import React from "react"
import { StaticQuery, Link } from "gatsby"
import { graphql } from "gatsby"
import Logo from "./logo.js"
import styled from "styled-components"
import Bg from "../images/bg.gif"

const Intro = styled.header`
  @media screen and (max-width: 700px) {
    padding: 0 1em;
  }

  svg {
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    display: block;
    top: 250px;

    @media screen and (max-width: 850px) {
      top: 180px;
    }

    @media screen and (max-width: 600px) {
      top: 50px;
    }
  }

  img {
    margin: 0;
  }

  h2 {
    position: relative;
    text-align: center;
    letter-spacing: 3px;
    font-size: 20px;
    top: -120px;
    @media screen and (max-width: 700px) {
      margin: 0;
      top: -60px;
    }

    @media screen and (max-width: 600px) {
      margin: 0;
      top: -140px;
    }

    @media screen and (max-width: 500px) {
      margin: 0;
      top: -60px;
    font-size: 12px;

    }
  }
`

const Header = () => (
  <StaticQuery
    query={graphql`
      query homepage {
        site {
          siteMetadata {
            description
          }
        }
      }
    `}
    render={data => (
      <Intro>
        <Logo />

        <img src={Bg} />
        <h2>Brand Consulting / Venice, CA</h2>
      </Intro>
    )}
  />
)

export default Header
